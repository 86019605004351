<template>
  <v-app
    :class="{ 'pattern-bg': light, 'pattern-bg-dark': dark }"
    v-if="!loading"
  >
    <v-navigation-drawer
      v-resize="onResize"
      v-model="drawer"
      app
      class="elevation-2"
      clipped
      v-if="menu != 'none'"
      :mini-variant.sync="mini"
      :expand-on-hover="$vuetify.breakpoint.mdAndUp"
      width="300px"
    >
      <MenuDashBoard v-if="menu === 'dashboard'" />
    </v-navigation-drawer>

    <v-app-bar
      class="elevation-2"
      :class="{ primary: light, 'panel-gradient-bg-dark': dark }"
      app
      clipped-left
      dark
    >
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        @click:outside="drawer = !drawer"
        @keydown.esc="drawer = !drawer"
      ></v-app-bar-nav-icon>

      <v-spacer></v-spacer>

      <v-btn dark icon @click="menuUsuario = !menuUsuario">
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </v-app-bar>

    <!-- menu lateral direto -->
    <MenuLateralDireito v-if="menuUsuario" :menuUsuario.sync="menuUsuario" />

    <v-main>
      <transition mode="out-in">
        <router-view />
      </transition>
    </v-main>

    <!-- <Profile :dialogProfile.sync="dialogProfile" /> -->
  </v-app>
  <v-app v-else>
    <v-main>
      <v-container class="fill-height align-center justify-center">
        <v-progress-circular
          :width="5"
          indeterminate
          :color="dark ? 'white' : 'primary'"
          size="80"
        ></v-progress-circular>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import { format } from "date-fns";

export default {
  name: "AdminLayout",
  components: {
    MenuLateralDireito: () => import("../components/MenuLateralDireito"),
    // LocaleSwitcher: () => import("@/components/LocaleSwitcher"),
    MenuDashBoard: () => import("./menus/MenuDashBoard"),
  },
  data() {
    return {
      drawer: false,
      menuUsuario: false,
      mini: true,
      locale: "",
      windowSize: {
        x: 0,
        y: 0,
      },
      apiUrl: null,
      dialogProfile: false,
      menu: "",
      loading: false,
    };
  },

  computed: {
    year() {
      return format(new Date(), "yyyy");
    },
    ...mapState({
      light: (state) => state.template.light,
      dark: (state) => state.template.dark,
    }),
    ...mapState("Usuario", {
      usuario_name: (state) => state.usuario.name,
      avatar: (state) => state.avatar,
    }),
    nome() {
      let splitName = this.usuario_name.split(/ /);
      let firstName = splitName[0] ? splitName[0] : "";
      let lastName = splitName[1] ? splitName[1] : "";
      firstName =
        firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
      lastName =
        lastName.charAt(0).toUpperCase() + lastName.slice(1).toLowerCase();
      let name = firstName + " " + lastName;
      return name;
    },
  },

  watch: {
    $route() {
      if (this.$route.meta.menu) {
        this.menu = this.$route.meta.menu;
      }
    },
  },

  methods: {
    deslogarUsuario() {
      this.loading = true;
      this.$store.dispatch("Usuario/deslogarUsuario").then(() => {
        this.$router.push("/login");
        this.loading = false;
      });
    },
    setTemplate() {
      if (this.light) {
        this.$store.commit("UPDATE_TEMPLATE", { dark: true, light: false });
        this.$ls.set("template", "dark");
        this.$vuetify.theme.dark = true;
      } else {
        this.$store.commit("UPDATE_TEMPLATE", { dark: false, light: true });
        this.$ls.set("template", "light");
        this.$vuetify.theme.dark = false;
      }
    },
    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
      if (this.windowSize.x < 1264) {
        return (this.mini = false);
      }
    },
  },
  mounted() {
    if (this.$route.meta.menu) {
      this.menu = this.$route.meta.menu;
    } else {
      this.menu = "dashboard";
    }
    if (process.env.VUE_APP_API_URL) {
      this.apiUrl = process.env.VUE_APP_API_URL;
    }

    this.onResize();
    this.locale = this.$i18n.locale;
  },
};
</script>

<style lang="scss" scoped>
.pattern-bg {
  background-color: #eeeeee !important;
}

.pattern-bg-dark {
  background-color: #3a3a3a !important;
}

.v-enter,
.v-leave-to {
  transform: translate3d(-20px, 0, 0);
  opacity: 0;
}

.v-enter-active,
.v-leave-active {
  transition: all 0.3s;
}
</style>
